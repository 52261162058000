import React, { Component } from 'react';
import { Menu, Image } from 'antd';
import 'components/Shared/MenuNavigation/MenuNavigation.css';
import icon from 'components/Shared/MenuNavigation/logo.png';
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

class MenuNavigation extends Component {
  state = {
    current: 'nearMe',
  };

  handleClick = e => {
    this.setState({ current: e.key });
  };

  render() {
    const { current } = this.state;
    return (
      <Menu onClick={this.handleClick} selectedKeys={[current]} mode="horizontal">

        <Link to="/">
          <Image
            preview={false}
            src={icon}
            className="salee_logo"
            />
        </Link>

        <Menu.Item key="nearMe">
          <Link to={"/property-for-sale-near-me"}>Near Me</Link>
        </Menu.Item>

        <Menu.Item key="blog">
          <Link to={"/blog"}>Blog</Link>
        </Menu.Item>

        {/*<SubMenu key="BlogMenu" title="Blog">
          <Menu.ItemGroup title="Buyers Guides">
            <Menu.Item key="setting:1">Finding the right location</Menu.Item>
            <Menu.Item key="setting:2">How much is too much?</Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Tech">
            <Menu.Item key="setting:3">Super search</Menu.Item>
            <Menu.Item key="setting:4">React native?</Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>*/}

        <Menu.Item key="apps">
          <Link to={"/mobile-apps"}>Mobile Apps</Link>
        </Menu.Item>

        <SubMenu key="MoreMenu" title="More">
            <Menu.Item key="more:1"><Link to={"/site-map"}>Properties by location</Link></Menu.Item>
            <Menu.Item key="more:2"><Link to={"/mission-statement"}>Mission Statement</Link></Menu.Item>
        </SubMenu>
      </Menu>

    );
  }
}

export default MenuNavigation;
