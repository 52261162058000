import React, { Component } from 'react';
import DropInBlogApi from 'apis/DropInBlogApi';
import { withRouter, Link } from "react-router-dom";
import { List, Row, Col, Card, Image, Skeleton, Typography, Divider} from 'antd';
import 'components/Views/BlogPostView/BlogPostView.css';
const { Title, Text } = Typography;

class BlogPostView extends Component {
  state = {
    post: {},
    loading: true
  };

  componentDidMount = async() => {
    const { match } = this.props;

    try {
      console.log(match.params);
      let result = await DropInBlogApi.blog(match.params.slug);
      console.log(result.data.data.post.content);
      this.setState({ post: result.data.data.post });
    } catch (error) {
      console.error('############## Failed to get all examples.', error);
    }
  }

  render() {
    return (
      <Row style={{ margin: 20 }}>
        <Col xs={0} md={4}></Col>
        <Col xs={24} md={16}>
          <Title>{this.state.post.title}</Title>
          <div dangerouslySetInnerHTML={{ __html: this.state.post.content }} />
        </Col>
        <Col xs={0} md={4}></Col>
      </Row>
    );
  }
}

export default withRouter(BlogPostView);
