import axios from 'axios';

class DropInBlogApi {
  static allBlogs() {
    return axios.get("https://api.dropinblog.com/v1/json/?b=Z02DMC4H31SNLQZHFKZ1");
  }

  static blog(slug) {
    return axios.get("https://api.dropinblog.com/v1/json/post/?b=Z02DMC4H31SNLQZHFKZ1&max-age=0&post=" + slug);
  }
}

export default DropInBlogApi;
