import React from 'react';
import { render } from 'react-snapshot';

import 'antd/dist/antd.css';
import 'index.css';

import App from 'components/App';

render(
  <App />,
  document.getElementById('root')
);
