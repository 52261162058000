import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { List, Row, Col} from 'antd';
import { MetaTags } from 'components/Shared';

class SiteMapView extends Component {
  render() {
    return (
      <div>
        <MetaTags />
        <Row gutter={[16, 16]} style={{ margin: 20 }}>
          <Col xs={24}><h1>Properties for sale in Northern Ireland</h1></Col>
          <Col xs={6}><Link to="/property-for-sale/south-belfast">South Belfast</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/university-area-belfast">University Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/holylands-area-belfast">Holylands Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/east-belfast">East Belfast</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/lower-lisburn-road-area-belfast">Lower Lisburn Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/north-belfast">North Belfast</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/holylands-belfast">Holylands</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/lisburn-road-area-belfast">Lisburn Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/belfast-city-centre">Belfast City Centre</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/malone-belfast">Malone</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/malone-road-area-belfast">Malone Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/botanic-belfast">Botanic</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/sydenham-belfast">Sydenham</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/cregagh-road-area-belfast">Cregagh Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/strandtown-belfast">Strandtown</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/upper-newtownards-road-belfast">Upper Newtownards Road</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/belfast-harbour-estate">Belfast Harbour Estate</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/holywood-road-area-belfast">Holywood Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/ravenhill-road-area-belfast">Ravenhill Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/ballyhackamore-belfast">Ballyhackamore</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/stormont-belfast">Stormont</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/belmont-road-area-belfast">Belmont Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/beersbridge-road-area-belfast">Beersbridge Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/knock-belfast">Knock</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/cavehill-belfast">Cavehill</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/shankill-road-area-belfast">Shankill Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/belfast-harbour-estate">Belfast Harbour Estate</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/ballysillan-belfast">Ballysillan</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/lower-antrim-road-belfast">Lower Antrim Road</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/shore-road-area-belfast">Shore Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/upper-antrim-road-belfast">Upper Antrim Road</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/oldpark-road-area-belfast">Oldpark Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/crumlin-road-area-belfast">Crumlin Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/ligoniel-belfast">Ligoniel</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/cliftonville-belfast">Cliftonville</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/woodvale-belfast">Woodvale</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/university-area-belfast">University Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/holylands-area-belfast">Holylands Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/lower-lisburn-road-area-belfast">Lower Lisburn Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/holylands-belfast">Holylands</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/lisburn-road-area-belfast">Lisburn Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/malone-belfast">Malone</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/malone-road-area-belfast">Malone Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/botanic-belfast">Botanic</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/stranmillis-belfast">Stranmillis</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/ormeau-road-area-belfast">Ormeau Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/lower-stranmillis-road-belfast">Lower Stranmillis Road</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/tates-avenue-area-belfast">Tates Avenue Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/dunmurry">Dunmurry</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/shankill-road-area-belfast">Shankill Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/andersonstown-belfast">Andersonstown</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/falls-road-area-belfast">Falls Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/ballygomartin-road-area">Ballygomartin Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/woodvale-belfast">Woodvale</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/springfield-road-area-belfast">Springfield Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/stewartstown-road-area-belfast">Stewartstown Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/glen-road-area-belfast">Glen Road Area</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/clonard-belfast">Clonard</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/lyndhurst-belfast">Lyndhurst</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/lagmore-belfast">Lagmore</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/belfast">Belfast</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/ballymena">Ballymena</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/lisburn">Lisburn</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/newtownabbey">Newtownabbey</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/carrickfergus">Carrickfergus</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/larne">Larne</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/ballymoney">Ballymoney</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/antrim">Antrim</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/ballyclare">Ballyclare</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/portrush">Portrush</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/dundonald">Dundonald</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/randalstown">Randalstown</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/newry">Newry</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/lurgan">Lurgan</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/portadown">Portadown</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/armagh">Armagh</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/craigavon">Craigavon</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/moira">Moira</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/tandragee">Tandragee</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/richhill">Richhill</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/gilford">Gilford</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/waringstown">Waringstown</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/aghalee">Aghalee</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/keady">Keady</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/lisburn">Lisburn</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/bangor">Bangor</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/newry">Newry</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/newtownards">Newtownards</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/downpatrick">Downpatrick</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/holywood">Holywood</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/banbridge">Banbridge</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/dromore-county-down">Dromore</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/dundonald">Dundonald</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/hillsborough">Hillsborough</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/ballynahinch">Ballynahinch</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/comber">Comber</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/enniskillen">Enniskillen</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/lisnaskea">Lisnaskea</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/kesh">Kesh</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/newtownbutler">Newtownbutler</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/irvinestown">Irvinestown</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/fivemiletown">Fivemiletown</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/aughnacloy">Aughnacloy</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/clogher">Clogher</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/belleek">Belleek</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/ballygawley">Ballygawley</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/garrison">Garrison</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/lisbellaw">Lisbellaw</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/londonderry">Derry / Londonderry</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/portstewart">Portstewart</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/coleraine">Coleraine</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/magherafelt">Magherafelt</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/limavady">Limavady</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/ballycastle">Ballycastle</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/portglenone">Portglenone</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/garvagh">Garvagh</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/draperstown">Draperstown</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/dungiven">Dungiven</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/eglinton">Eglinton</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/maghera-county-londonderry">Maghera</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/dungannon">Dungannon</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/omagh">Omagh</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/cookstown">Cookstown</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/strabane">Strabane</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/castlederg">Castlederg</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/coalisland">Coalisland</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/fivemiletown">Fivemiletown</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/aughnacloy">Aughnacloy</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/clogher">Clogher</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/fintona">Fintona</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/ballygawley">Ballygawley</Link></Col>
          <Col xs={6}><Link to="/property-for-sale/moy">Moy</Link></Col>
      </Row>

      <Row gutter={[16, 16]} style={{ margin: 20 }}>
        <Col xs={24}><h1>Properties for sale in Republic of Ireland</h1></Col>
        <Col xs={6}><Link to="/property-for-sale/county-carlow">County Carlow</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-cavan">County Cavan</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-clare">County Clare</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-cork">County Cork</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-donegal">County Donegal</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/dublin-county">County Dublin</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-galway">County Galway</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-kerry">County Kerry</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-kildare">County Kildare</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-kilkenny">County Kilkenny</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-laois">County Laois</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-leitrim">County Leitrim</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-limerick">County Limerick</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-longford">County Longford</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-louth">County Louth</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-mayo">County Mayo</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-meath">County Meath</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-monaghan">County Monaghan</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-offaly">County Offaly</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-roscommon">County Roscommon</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-sligo">County Sligo</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-tipperary">County Tipperary</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-waterford">County Waterford</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-westmeath">County Westmeath</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-wexford">County Wexford</Link></Col>
        <Col xs={6}><Link to="/property-for-sale/county-wicklow">County Wicklow</Link></Col>
      </Row>

      <br/><br/>
    </div>
    );
  }
}

export default SiteMapView;
