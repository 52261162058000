import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { MenuNavigation } from 'components/Shared';
import { HomeView, SearchView, PropertyView, MobileAppsView, SiteMapView, MissionStatementView, BlogPostsView, BlogPostView } from 'components/Views';
import 'components/App/App.css';

import ReactGA from 'react-ga';
const TRACKING_ID = "G-3S3B6JLB8G";
ReactGA.initialize(TRACKING_ID);

//Where else ;)?
String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <MenuNavigation />
        <Switch>
          <Route exact path="/">
            <HomeView />
          </Route>

          <Route path={`/property-for-sale/:location`}>
            <SearchView />
          </Route>

          <Route path={`/property-for-sale-near-me`}>
            <SearchView />
          </Route>

          <Route path={`/for-sale/details/:id`}>
            <PropertyView />
          </Route>

          <Route path={`/mobile-apps`}>
            <MobileAppsView />
          </Route>

          <Route path={`/site-map`}>
            <SiteMapView />
          </Route>

          <Route path={`/mission-statement`}>
            <MissionStatementView />
          </Route>

          <Route path={`/blog/:slug`}>
            <BlogPostView />
          </Route>

          <Route path={`/blog`}>
            <BlogPostsView />
          </Route>

        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
