import React, { Component } from 'react';
import { Row, Col, Image, Typography, Space } from 'antd';
import preview from 'components/Views/MobileAppsView/preview.png';
import destination from 'components/Views/MobileAppsView/Salee-Destinations-iOS.png';
import search from 'components/Views/MobileAppsView/Salee-Search-iOS.png';
import seller from 'components/Views/MobileAppsView/Salee-Seller-iOS.png';
import apple from 'components/Views/MobileAppsView/apple.svg';
import { MetaTags } from 'components/Shared';
const { Title, Text, Paragraph } = Typography;

class MobileAppsView extends Component {
  render() {
    return (
      <div>
        <MetaTags />
        <Row align="middle">
          <Col xs={1} lg={2}></Col>
          <Col xs={23} lg={9}>
            <Space direction="vertical">
              <Title>A mobile experience you will love</Title>
              <Title level={4}>Don’t miss out on the right home for you — browse up-to-date listings, refine your search and more.</Title>
              <Text>Coming Soon</Text>
              <Image preview={false} src={apple} height={40} width={120}/>
            </Space>
          </Col>
          
          <Col xs={0} lg={13}>
            <Image preview={false} src={preview}  height={600} width={930}/>
          </Col>
        </Row>

        <Row align="middle">
          <Col xs={0} lg={4}></Col>
          <Col xs={24} lg={5}>
            <Image preview={false} src={destination} height={650} width={342}/>
          </Col>
          <Col xs={1} lg={3}></Col>
          <Col xs={23} lg={10}>
            <Space direction="vertical">
              <Title>Map search reimagined</Title>
              <Title level={4}>Location is everything when buying a home, travel times, statistics, and more. Everything you need to know right in your hand.</Title>
              <Text>Coming Soon</Text>
              <Image preview={false} src={apple} height={40} width={120}/>
            </Space>
          </Col>
          <Col xs={0} lg={2}></Col>
        </Row>

        <Row align="middle">
          <Col xs={0} lg={4}></Col>
          <Col xs={24} lg={5}>
            <Image preview={false} src={search} height={650} width={342}/>
          </Col>
          <Col xs={1} lg={3}></Col>
          <Col xs={23} lg={10}>
            <Space direction="vertical">
              <Title>Search, but not as you know it</Title>
              <Title level={4}>Find properties by the smallest details that matter to you, search for styles, architecture, renovation projects, or even features.</Title>
              <Text>Coming Soon</Text>
              <Image preview={false} src={apple} height={40} width={120}/>
            </Space>
          </Col>
          <Col xs={0} lg={2}></Col>
        </Row>

        <Row align="middle">
          <Col xs={0} lg={4}></Col>
          <Col xs={24} lg={5}>
            <Image preview={false} src={seller} height={650} width={342}/>
          </Col>
          <Col xs={1} lg={3}></Col>
          <Col xs={23} lg={10}>
            <Space direction="vertical">
              <Title>Sell your own home with Salee</Title>
              <Title level={4}>Sell your home or support an agent by listing your home with us, its easy and quick.</Title>
              <Text>Coming Soon</Text>
              <Image preview={false} src={apple} height={40} width={120}/>
            </Space>
          </Col>
          <Col xs={0} lg={2}></Col>
        </Row>

        <Row align="middle">
          <Col xs={1} md={4}></Col>
          <Col xs={23} md={20}>
            <br />
            <br />
            <Space direction="vertical">
              <Title>Android coming soon</Title>
              <Title level={4}>The same great experence will be native to Android too.</Title>
              <br />
              <br />
              <br />
            </Space>

          </Col>
        </Row>

      </div>
    );
  }
}

export default MobileAppsView;
