import React, { Component } from 'react';
import { Helmet as Tags } from 'react-helmet';

class MetaTags extends Component {
  static defaultProps = {
    title: "Property For Sale and to Rent in Ireland and Northern Ireland - Salee",
    description: "Search by the details that matter, properties for sale and to rent in Northern Ireland and Ireland",
    image: "https://www.salee.io/Salee-Site-Preview.png"
  };

  render() {
    return (
      <Tags>
        <meta charset="utf-8" />
        <link rel="shortcut icon" href={window.location.origin + "/favicon.ico"}/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <meta name="theme-color" content="#EF5A1E"/>
        <link rel="manifest" href={window.location.origin + "/manifest.json"}/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>

        <title>{this.props.title}</title>
        <meta name="title" content={this.props.title}/>
        <meta name="description" content={this.props.description}/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content={window.location.href}/>
        <meta property="og:title" content={this.props.title}/>
        <meta property="og:description" content={this.props.description}/>
        <meta property="og:image" content={this.props.image}/>

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content={window.location.href}/>
        <meta property="twitter:title" content={this.props.title}/>
        <meta property="twitter:description" content={this.props.description}/>
        <meta property="twitter:image" content={this.props.image}/>
      </Tags>
    );
  }
}

export default MetaTags;
