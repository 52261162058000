import React, { ChangeEvent, useState } from "react"
import { useHistory } from "react-router-dom"
import { Input, Button, Space } from 'antd';

const SearchInput: React.FC = () => {
  const [query, setQuery] = useState("")
  const history = useHistory()

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setQuery(e.target.value)
  }

  function onPressEnter() {
    history.push("/property-for-sale/" + query)
  }

  function onSaleClicked() {
    history.push("/property-for-sale/" + query)
  }

  function onRentClicked() {
    history.push("/property-for-rent/" + query)
  }

  return <div>
    <Input size="large" value={query} placeholder="Search Area, Address, Style" onChange={onChange} onPressEnter={onPressEnter} />

    <Space >
      <Button type="primary" shape="round" size="large" onClick={onSaleClicked}>
        For Sale
      </Button>
    </Space>
  </div>
}

export default SearchInput
