import React, { Component } from 'react';
import { Row, Col, Divider, Image, Typography, Space } from 'antd';
import preview from 'components/Views/MobileAppsView/preview.png';
import destination from 'components/Views/MobileAppsView/Salee-Destinations-iOS.png';
import search from 'components/Views/MobileAppsView/Salee-Search-iOS.png';
import seller from 'components/Views/MobileAppsView/Salee-Seller-iOS.png';
import apple from 'components/Views/MobileAppsView/apple.svg';
import { MetaTags } from 'components/Shared';
const { Title, Text, Paragraph } = Typography;

class MissionStatementView extends Component {
  render() {
    return (
      <div>
        <MetaTags />

        <Row style={{ margin: 20 }}>
          <Typography>
            <br/>
            <Title>Salee Mission Statement</Title>
            <Paragraph>Bring the latest technology and innovation to the property market. <Text strong>Solve problems, provide support, improve processes.</Text></Paragraph>
            <Paragraph>Buyers should have all the information to make a well informed decision on viewing and buying a house.</Paragraph>
            <Paragraph>Agents should be able to work from anywhere with automation at hand and that is easy to use. </Paragraph>
          </Typography>
        </Row>

        <Row style={{ margin: 20 }}>
          <Col md={24}>
            <br/><br/>
            <Title level={2}>Buyer Roadmap</Title>
            <Text type="secondary">Our feature goals for the user who wants to find their next home on Salee.io</Text>
            <br/><br/><br/>
          </Col>

          <Col md={7} style={{ padding: 20 }}>
            <Title level={4}>Smarter property search</Title>
            <Text>
              Search should be and can be more than a location with filters. Lets think bigger than that, find specific features you
              need in your next home, Examples might be: “downstairs toilet”, “sea views”, or “needs renovation”.
            </Text>
          </Col>

          <Col md={1}></Col>

          <Col md={7} style={{ padding: 20 }}>
            <Title level={4}>Information that matters</Title>
            <Text>
              Price and images are super important but is there more information that would help you pick a new home?
              What about the exact cost of living there, internet speeds, travel times, community, schools?
              Its a huge decision you should have all the knowledge you can before making an offer, we aim to provide it.
            </Text>
          </Col>

          <Col md={1}></Col>

          <Col md={8} style={{ padding: 20 }}>
            <Title level={4}>Support</Title>
            <Text>
              Buying a home is a complicated stressful and sometimes a long process,
              we will be there to guide and support you along the way. Videos, blogs, communities, and chat. Never feel lost or alone when when buying your new home.
            </Text>
          </Col>
        </Row>

        <Row style={{ margin: 20 }}>
          <Col md={24}>
            <br/><br/>
            <Title level={2}>Agent Roadmap</Title>
            <Text type="secondary">Our feature goals for the agents and sellers who want to list a property using Salee.io</Text>
            <br/><br/><br/>
          </Col>

          <Col md={7} style={{ padding: 20 }}>
            <Title level={4}>Modern Tools</Title>
            <Text>
              Fed up running your business through outdated software? We are building a suite of apps that will enable you to work on the road using a tablet or mobile.
              Upload your listings, manage leads, update your social media, and organise third parties.
            </Text>
          </Col>

          <Col md={1}></Col>

          <Col md={7} style={{ padding: 20 }}>
            <Title level={4}>Analytics</Title>
            <Text>
              Are you writing the best descriptions? Is your main image the best one? What are people searching for when they view your listing?
              All this information should be at hand when you come to writing a new listing, helping your get more eyes on your properties.
            </Text>
          </Col>

          <Col md={1}></Col>

          <Col md={8} style={{ padding: 20 }}>
            <Title level={4}>Guidance and Training</Title>
            <Text>
              How can you utilise technology to sell more houses? What can I do to save time and resources?
              How can I stay up to date on emerging trends in the property market.
              These are the sort of questions we aim to help with, we want you to sell more houses and the software we make is going to help you do it.
            </Text>
          </Col>

        </Row>
        <br/><br/><br/><br/><br/><br/>
      </div>
    );
  }
}

export default MissionStatementView;
