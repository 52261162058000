import axios from 'axios';
import { apiEndpoints } from 'salee-shared';

class PropertyApi {
  static searchSaleeByLocation(location) {
    return axios.get(apiEndpoints.property.searchSaleeByLocation.replace(':location', location));
  }

  static searchSaleeByCoordinate(coordinate) {
    return axios.get(apiEndpoints.property.searchSaleeByCoordinate + "?lat=" + coordinate.lat + "&lng=" + coordinate.lng);
  }
}

export default PropertyApi;
