import React, { Component } from 'react';
import { SearchInput, MetaTags } from 'components/Shared';
import { Row, Col } from 'antd';
import { Link } from "react-router-dom";

class HomeView extends Component {
  render() {
    return (
      <div>
        <MetaTags />

        <div className="hero-image">
          <Row align="middle">
            <Col xs={2} md={6}></Col>
            <Col xs={20} md={12}>

              <div className="hero-text">
                More than a house, find your home
                <SearchInput />
              </div>

            </Col>
            <Col xs={2} md={6}></Col>
          </Row>

        </div>
      </div>
    );
  }
}

export default HomeView;
